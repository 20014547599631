<template>
  <div class="box" v-loading="loading">
    <el-row type="flex" justify='center' align='middle'>
      <el-col :span="2" class="box-img">
        <img title="放大音量" :src="imgData['addSound']" @click="addSound"> 
      </el-col>
      <el-col :span="2" class="box-img">
        <img title="减小音量" :src="imgData['reduceSound']" @click="reduceSound">
      </el-col>
      <el-col :span="2" class="box-img">
        <el-popover placement="top" trigger="hover">
          <el-slider v-model="volume" :marks="marks" height="100px" @change="volumeChange" :min='0' :max='100'></el-slider>
          <img title="音量" slot="reference" :src="sound?imgData['mute']:imgData['sound']" @click="changeSound">
        </el-popover>
      </el-col>
      <el-col :span="4"  class="box-img" :offset='1'>
        <img :title="switchStatus?'暂停':'播放'" :src="switchStatus?imgData['stop']:imgData['play']" @click="changeSwitch">
      </el-col>
      <el-col :span="2" class="box-img" :offset='1'>
        <img title="上一首" :src="imgData['prev']"  @click="prevMusic">
      </el-col>
      <el-col :span="2" class="box-img">
        <img title="下一首" :src="imgData['next']" @click="nextMusic">
      </el-col>
      <el-col :span="2" class="box-img">
        <img :title="playbackMode?'循环播放':'列表播放'" :src="playbackMode?imgData['loop']:imgData['order']" @click="changePlaybackMode">
      </el-col>
    </el-row>
    <el-row class="box-but">
      <MusicTable :title="'首页'"/>
    </el-row>
    <el-row class="box-but">
      <el-col :span="8" align="right"><el-button type="info" class="devButGrey" @click="resourcesBut">资源管理</el-button></el-col>
      <el-col :span="8" align="center"><el-button type="info" class="devButGrey">设备休眠</el-button></el-col>
      <el-col :span="8" align="left"><el-button type="info" class="devButGrey">设备唤醒</el-button></el-col>
    </el-row>
    <el-dialog
        width="1200px"
        v-dialogDrag
        title="本地播放器资源管理"
        :modal-append-to-body="false"
        :visible.sync="resourcesMusic"
        append-to-body
    >
      <ResourcesMusic v-if="resourcesMusic" :row="row" ref="ResourcesMusic"/>
    </el-dialog>
    </div>
</template>

<script>
import imgJSON from './JSON/img.JSON'
import ResourcesMusic from './localhostMusic/resourcesMusic'
import MusicTable from './localhostMusic/musicTable'

import { operationDevice } from '@/api/device'

export default {
  
  props:{
    row:Object
  },
    data() {
        return {
            sound:false,
            loading:false,
            resourcesMusic:false,
            imgData:imgJSON,
            switchStatus:false,
            playbackMode:false,
            tableData:[],
            volume:null,
            oldVolume:null,
            marks:{ 
              25: '25',
              50: '50',
              75: '75',
            }
        }
    },
    components:{
      ResourcesMusic,
      MusicTable
    },
    created() {
    },
    methods:{
      prevMusic(){
        this.control('上一首')
      },
      nextMusic(){
        this.control('下一首')
      },
      addSound(){
        this.volume++
        this.control('音量加')
      },
      reduceSound(){
        this.volume--
        this.control('音量减')
      },

      resourcesBut(){
        this.resourcesMusic = true
      },
      changeSound(){
        this.sound =!this.sound
        console.log(this.oldVolume, this.volume,this.sound)
        if(this.sound){
          this.oldVolume = this.volume
          this.volume = 0
        }else{
          this.volume = this.oldVolume
        }
        this.control("指定音量")
      },
      volumeChange(){
        this.control("指定音量")
      },
      changeSwitch(){
        if(this.switchStatus){
          this.control('暂停').then(res=>{
            this.switchStatus = !this.switchStatus
          })
        }else{
          this.control('播放').then(res=>{
            this.switchStatus = !this.switchStatus
          })
        }
      },
      changePlaybackMode(){
        //true 循环 false 列表
        if(this.playbackMode){
          this.control('单曲循环播放关闭').then(res=>{
            this.playbackMode = !this.playbackMode
          })
        }else{
          this.control('单曲循环播放开启').then(res=>{
            this.playbackMode = !this.playbackMode
          })

        }
      },

      control(status){
         return new Promise((resolve,reject)=>{
            operationDevice({
            devId:this.row.jourDeviceId,
            operationType:status,
            volume:this.volume
          }).then(res=>{
            let result = JSON.parse(res.data.result)
            this.$message.success(`控制${result.msg}`)
            if(result.code === 0){
                return resolve()
            }
          }).catch(err=>{
            this.isError(err,this)
              return reject()
          })
         })
      }
    }
}
</script>

<style lang="scss" scoped>
@import '../devButStyle.scss';
.box{
  .box-img{
    text-align: center;
  }
  .box-but{
    margin-top: 10px;
  }
}
img{
  cursor: pointer;
  width: 80%;
  height:auto;
   -webkit-user-drag: none;
}
</style>