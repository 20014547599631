
export const mixin = {
    //过滤器
    filters:{
        musicSource(value) {
            switch (value) {
                case 1:
                    return 'U盘'
                case 2:
                    return '内存卡'
                case 3:
                    return 'FLASH'
                case 4:
                    return '网络'
                default:
                    break;
            }
        }
    },
    methods:{
    }
}

