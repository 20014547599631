import request from '../utils/request';

export function getBatchState(params){
    return request({
        url: `/common2/devices/getDevicesState`,
        method:'GET',
        params
    })
}

// 设备控制
export function operationDevice(data){
    return request({
        url:'/common2/devices/operate',
        method:'POST',
        data
    })
}

export function deviceMatching(id){
    return request({
        url:`/common2/devices/deviceMatching/${id}`,
        method:'GET'
    })
}

export function getAuthorizationRecord(id,params){
    return request({
        url:`/common2/doorCard/getAuthorizationRecord/${id}`,
        method:'GET',
        params
    })
}

export function getLockRecords(sn,params){
    return request({
        url:`/common2/GateLockRecord/${sn}/getLockRecords`,
        method:'GET',
        params
    })
}

export function getGatewayHangDevice(sn){
    return request({
        url:`/common2/devices/getGatewayHangDevice/${sn}`,
        method:'GET',
    })
}

export function smokeAlertEvents(sn){
    return request({
        url:`/common2/smokeAlertEvents`,
        method:'GET',
        params:{
            sn
        }
    })
}

export function plugInCards(params){
    return request({
        url:'/common2/plugInCards',
        method:'GET',
        params
    })
}

export function getPageBoxAlarm(id,params){
    return request({ 
        url:`/common2/devices/${id}/getPageBoxAlarm`,
        method:'GET',
        params
    })
}

export function putDeviceDevNickname(id,params){
    return request({
        method:"PUT",
        url:`/common2/devices/${id}`,
        params
    })
}

export function getScenarioModeByHsId(params){
    return request({
        method:'GET',
        url:'/common2/scenarioPatternTables/getScenarioModeByHsId',
        params
    })
}

export function modeControl(id){
    return request({
        method:'POST',
        url:'/common2/scenarioPatternTables/modeControl',
        params:{
            jsroId:id
        }
    })
}

// 修改空气检测闹钟接口
export function changeDetector(id,data){
    return request({
        method:'PUT',
        url:`/common2/deviceInformation/${id}`,
        data,
    })
}

//音乐专辑
export function musicAlbumAddMusic(data){
    return request({
        method:'post',
        url:`/common2/musicAlbum/addMusic`,
        data,
    })
}

//清空音乐
export function resListDelete(params){
    return request({
        method:'delete',
        url:`/common2/musicResList/deleteMusicById`,
        params,
    })
}
//清空专辑
export function musicAlbumDeleteMusic(params){
    return request({
        method:'delete',
        url:`/common2/musicAlbum/deleteMusic`,
        params,
    })
}

//可复用数据
export function getMusicElseDevice(params){
    return request({
        method:'get',
        url:`/common2/musicResList/getMusicElseDevice`,
        params,
    })
}

//复用数据
export function copyMusicData(params){
    return request({
        method:'post',
        url:`/common2/musicResList/copyMusicData`,
        params,
    })
}
//添加音乐
export function musicResListAddList(data){
    return request({
        method:'post',
        url:`/common2/musicResList/addList`,
        data,
    })
}

//查询所有专辑
export function musicAlbumFindMusic(data){
    return request({
        method:'post',
        url:`/common2/musicAlbum/findMusic`,
        data,
    })
}

//批量删除专辑
export function musicAlbumDeleteMusicById(data){
    return request({
        method:'delete',
        url:`/common2/musicAlbum/deleteMusicById`,
        data,
    })
}

//音乐分页
export function musicResListFindMusicPage(params){
    return request({
        method:'get',
        url:`/common2/musicResList/findMusicPage`,
        params,
    })
}

//批量删除音乐
export function musicResListDeleteMusicResById(data){
    return request({
        method:'delete',
        url:`/common2/musicResList/deleteMusicResById`,
        data,
    })
}