<template>
    <div>
        <el-row>
            <el-col :span="24" v-for="(formItem,index) in formList" :key="index">
                <el-form :inline="true" :model="formItem" :ref="formItem.ref">
                    <el-form-item label="专辑名称：" prop="maAlbumName" label-width="100px" :rules="[{ required: true, message: '专辑名称不能为空'}]">
                        <el-input v-model="formItem.maAlbumName" size="small" placeholder="请输入专辑名称"></el-input>
                    </el-form-item>
                    <el-form-item label="专辑序号：" prop="maSerialNum" label-width="100px" :rules="[{ required: true, message: '专辑序号不能为空'},{ type: 'number', message: '专辑序号必须为数字值'},,{ validator: maSerialNumCheckAge, trigger: 'blur' }]">
                        <el-input v-model.number="formItem.maSerialNum" size="small" placeholder="请输入专辑序号"></el-input>
                    </el-form-item>
                    <el-form-item label="专辑音源：" prop="maAlbumSource" label-width="100px" :rules="[{ required: true, message: '专辑音源不能为空'}]">
                        <el-select v-model="formItem.maAlbumSource" placeholder="请选择专辑音源" size="small" >
                            <el-option v-for="item in source" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" icon="el-icon-delete" size="small" @click='deleAlbumRow(index)'  v-if="!(formList.length-1 == index) || formList.length > 1"></el-button>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click='addAlbumRow(index)'  v-if="index===0"></el-button>
                    </el-form-item>
                </el-form>
                </el-col>
            </el-row>
        <el-row>
            <el-col :span="23" align="right">
                <el-button type="primary" size="small" @click="addAlbum">添加专辑</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { musicAlbumAddMusic } from '@/api/device'
export default {
    props:{
        row:Object,
        getAllAlbum:Function,
    },
    data() {
        return {
            loading:false,
            dialog:false,
            formList:[],
            formItem:{
                maAlbumName:'',
                maAlbumSource:'',
                maSerialNum:''
            },
            source:[
                {value:1,name:'U盘'},
                {value:2,name:'内存卡'},
                {value:3,name:'FLASH'},
                {value:4,name:'网络'},
            ],
        };
    },
    watch:{
       formList:{
            handler: function(val, oldVal) {
                this.$nextTick(()=>{
                    if(val.length < 1) {
                    let formItem = this.$options.data().formItem
                        val.unshift({...formItem,ref:`${'ref'+val.length+new Date().valueOf()}`})
                    }
                })
            },
            //子组件想监听父组件传来的值需要设置immediate为true 初始化监听
            immediate:true,
            //侦听一个对象，每个属性值的变化都会执行handler
            deep:true,
        }
    },
    created() {
    },
    methods:{
          maSerialNumCheckAge(rule, value, callback){
                if (value<0 || value >99) {
                  return callback(new Error('只能输入0~99的序号'));
                } else {
                  return callback();
                }
        },
        addAlbumRow(){
            let formItem = this.$options.data().formItem
            this.formList.unshift({...formItem,ref:`${'ref'+this.formList.length+new Date().valueOf()}`})
        },
        //判断表单验证
        checkForm(arrName) {
            let flag = true
            this.$refs[arrName][0].validate((valid) => {
                if (valid) {
                    return true
                } else {
                flag = false;
                return false }
            })
            return flag
        },

        deleAlbumRow(index){
            this.formList.splice(index,1)
        },
        addAlbum(){
            let flag = false
            for(let i in this.formList){
                let item = this.formList[i]
                let result = this.checkForm(item.ref)
                if(!result){
                    flag = true;
                    continue
                }
            }
            if(flag){return}
            // this.formList.forEach((value, index) => {
	        // 	 value["maDevId"] = this.row.jourDeviceId;
            // });
            let arr = JSON.parse(JSON.stringify(this.formList))
            arr.map(item =>{
	        	item["maDevId"] = this.row.jourDeviceId;
                item.maSerialNum =item.maSerialNum.toString()
                return item
            })
            musicAlbumAddMusic(arr).then(res=>{
                this.$message.success(res.data.success)
                this.getAllAlbum()
            }).catch(err=>{
                this.isError(err,this)
            });
        },
        info(){
            this.dialog = true
        }
    }
}
</script>

<style>

</style>