<template>
  <div>
    <el-row>
      <el-table ref="multipleTable" :data="tableData" height="450px" style="width: 100%" v-loading="loading" highlight-current-row  @selection-change="handleSelectionChange" @row-dblclick="dblClickMusic">
        <el-table-column type="selection" width="55" v-if="title !== '首页'"></el-table-column>
        <el-table-column prop="maSerialNum" label="专辑序号" align="center" v-if="title !== '首页'"></el-table-column>
        <el-table-column prop="maAlbumName" label="专辑名称" align="center"></el-table-column>
        <el-table-column prop="mrMusicSerialNum" label="音乐序号" align="center"  v-if="title !== '首页'"></el-table-column>
        <el-table-column prop="mrMusicName" label="音乐名称" align="center"></el-table-column>
        <el-table-column prop="mrSingerName" label="歌手名称" align="center"></el-table-column>
        <el-table-column prop="mrMusicSource" label="音乐来源" align="center">
            <template slot-scope="scope">
                <!-- musicSource mixin混入里面的过滤器 -->
                <span style="margin-left: 10px">{{ scope.row.mrMusicSource | musicSource }}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
            <template slot-scope="scope">
                <el-button v-if="title=='首页'" type="primary" size="small" @click="playMusic(scope.row)" icon="el-icon-video-play" >播放</el-button>
                <el-tooltip content="编辑" placement="left" effect="light">
                    <el-button v-if="title!=='首页'" type="primary" size="small" icon="el-icon-edit" circle></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="right" effect="light">
                    <el-button v-if="title!=='首页'" type="danger" size="small" icon="el-icon-delete" @click="deleMusic(scope.row)" circle></el-button>
                </el-tooltip>
                <!-- <i v-else @click="deleMusic(scope.row)" class="el-icon-delete"></i> -->
            </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
        <el-col :span="24" align="center">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="params.cursor"
              :page-sizes="[5, 10, 20, 40]"
              :page-size="params.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="params.total">
            </el-pagination>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import { musicResListFindMusicPage , musicResListDeleteMusicResById } from '@/api/device'
import { mixin } from './mixins/mixin'

export default {
    mixins: [mixin],
    props:{
        title:String
    },
    data() {
        return {
            loading:false,
            tableData:[],
            params:{
                cursor: 1, // 当前页数
                limit: 10, // 当前条数
                total: 0,
            },
        }
    },
    created() {
        this.initialPage()
    },
    methods: {
        handleSizeChange(val){
            this.params.limit = val
            this.initialPage()
        },
        handleCurrentChange(val){
            this.params.cursor = val
            this.initialPage()
        },
        initialPage(){
            this.loading = true
            musicResListFindMusicPage(this.params)
            .then( res => {
                this.params.total = res.data.total
                this.params.size = res.data.size
                this.tableData = res.data.records
                this.loading = false
            }).catch( err => {
                this.loading = false
                this.isError(err,this)
            })
        },
        deleMusic(row){
            console.log(row)
            musicResListDeleteMusicResById([row.mrId]).then(({data})=>{
                console.log(data)
                this.$message.success(data.msg)
                this.initialPage()
            }).catch(err=>{
                this.isError(err,this)
            })
        },
        handleSelectionChange(val){
            console.log(val)
            this.$emit('selctMusic',val);
        },
        playMusic(){

        },
        dblClickMusic(row){
            this.$refs.multipleTable.toggleRowSelection(row);

        }
    },
}
</script>

<style>

</style>