<template>
    <div>
        <el-row>
            <el-col :span="24" v-for="(formItem,index) in formList" :key="index">
                <el-form :inline="true" :model="formItem" :ref="formItem.ref">
                   <el-form-item label="专辑：" prop="mrMaId" label-width="100px" :rules="[{ required: true, message: '专辑不能为空'}]">
                        <el-select v-model="formItem.mrMaId" placeholder="请选择专辑" size="small" filterable >
                            <el-option v-for="item in albumList" :key="item.maId" :label="item.maAlbumName" :value="item.maId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="歌手名称：" prop="mrSingerName" label-width="100px" :rules="[{ required: true, message: '歌手名称不能为空'}]">
                        <el-input v-model="formItem.mrSingerName" size="small" placeholder="请输入歌手名称"></el-input>
                    </el-form-item>
                    <el-form-item label="音乐名称：" prop="mrMusicName" label-width="100px" :rules="[{ required: true, message: '音乐名称不能为空'}]">
                        <el-input v-model="formItem.mrMusicName" size="small" placeholder="请输入音乐名称"></el-input>
                    </el-form-item>
                    <el-form-item label="音乐序号：" prop="mrMusicSerialNum" label-width="100px" :rules="[{ required: true, message: '音乐序号不能为空'},{ type: 'number', message: '音乐序号必须为数字值'},{ validator: maSerialNumCheckAge, trigger: 'blur' }]">
                        <el-input v-model.number="formItem.mrMusicSerialNum" size="small" placeholder="请输入音乐序号"></el-input>
                    </el-form-item>
                    <el-form-item label="音乐路径：" prop="mrMusicUrl" label-width="100px" :rules="[{ required: true, message: '音乐url不能为空'}]">
                        <el-input v-model="formItem.mrMusicUrl" size="small" placeholder="请输入音乐路径"></el-input>
                    </el-form-item>
                    <el-form-item label="音乐来源：" prop="mrMusicSource" label-width="100px" :rules="[{ required: true, message: '音乐来源不能为空'}]">
                        <el-select v-model="formItem.mrMusicSource" placeholder="请选择音乐来源" size="small" >
                            <el-option v-for="item in source" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" icon="el-icon-delete" size="small" @click='deleMusic(index)'  v-if="!(formList.length-1 == index) || formList.length > 1"></el-button>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click='addMusicRow(index)'  v-if="index===0"></el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="23" align="right">
                <el-button type="primary" size="small" @click="addMusic">添加音乐</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { musicResListAddList } from '@/api/device'
export default {
    props:{
        row:Object,
        albumList:Array,
        initialPage:Function,
    },
    data() {
        return {
            loading:false,
            dialog:false,
            formList:[],
            // albumList:[],
            formItem:{
                mrMaId:'',
                mrSingerName:'',
                mrMusicName:'',
                // maAlbumSource:'',
                mrMusicSource:'',
                mrMusicSerialNum:'',
                mrMusicUrl:'',
            },
            source:[
                {value:1,name:'U盘'},
                {value:2,name:'内存卡'},
                {value:3,name:'FLASH'},
                {value:4,name:'网络'},
            ],
        };
    },
    watch:{
       formList:{
            handler: function(val, oldVal) {
                this.$nextTick(()=>{
                    console.log(val,oldVal)
                    if(val.length < 1) {
                    let formItem = this.$options.data().formItem
                        val.unshift({...formItem,ref:`${'ref'+val.length+new Date().valueOf()}`})
                    }
                })
            },
            //子组件想监听父组件传来的值需要设置immediate为true 初始化监听
            immediate:true,
            //侦听一个对象，每个属性值的变化都会执行handler
            deep:true,
        }
    },
    created() {
        console.log(this.row)
    },
    methods:{
        maSerialNumCheckAge(rule, value, callback){
            if (value<0 || value >99) {
              return callback(new Error('只能输入0~99的序号'));
            } else {
              return callback();
            }
        },
        addMusicRow(){
            let formItem = this.$options.data().formItem
            this.formList.unshift({...formItem,ref:`${'ref'+this.formList.length+new Date().valueOf()}`})
        },
        //判断表单验证
        checkForm(arrName) {
            let flag = true
            this.$refs[arrName][0].validate((valid) => {
                if (valid) {
                    return true
                } else {
                flag = false;
                return false }
            })
            return flag
        },

        deleMusic(index){
            console.log(index)
            this.formList.splice(index,1)
        },
        addMusic(){
            let flag = false
            for(let i in this.formList){
                let item = this.formList[i]
                let result = this.checkForm(item.ref)
                console.log(result)
                if(!result){
                    flag = true;
                    continue
                }
            }
            console.log(flag)
            if(flag){return}
            let arr = this.formList.map(item =>{
	        	item["mrDevId"] = this.row.jourDeviceId;
                item.mrMusicSerialNum.toString()
                return item
            })
            console.log('hh')
            musicResListAddList(arr).then(res=>{
                console.log(res)
                this.$message.success(res.data.success)
                this.$emit('initialPage')
            }).catch(err=>{
                this.isError(err,this)
            });
        },
        info(){
            this.dialog = true
        }
    }
}
</script>

<style>

</style>