<template>
    <div>
        <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="500px"
            v-loading="loading"
            @row-click="rowClickData">
          <el-table-column
            property="address"
            label="设备地址">
            <template slot-scope="scope"> 
                <div>
                    {{ scope.row.hsAddCommunity }} {{scope.row.hsAddFloor}} {{scope.row.hsAddDoorplateno}}
                </div>
            </template>
          </el-table-column>
          <el-table-column
            property="devNickname"
            label="设备名称">
          </el-table-column>
          <el-table-column
            property="devSn"
            label=" 设备SN">
          </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getMusicElseDevice,copyMusicData } from '@/api/device'

export default {
    props:{
        row:Object
    },
    data() {
        return {
            dialog:false,
            loading:false,
            tableData: [],
            search:'',
            addressSearch:'',
            devNicknameSearch:'',
            devSnSearch:'',
            rowData:'',
        }
    },
    created(){
        this.getTableData()
    },
    methods: {
        info(){
            this.dialog = true
        },
        rowClickData(row){
            this.rowData = row
        },
        defineMultiplexed(){
            if(!this.rowData){
                this.$message.warning('请选择一条设备数据')
                return
            }
            copyMusicData({
                jourDeviceIdA:this.rowData.jourDeviceId,
                jourDeviceIdB:this.row.jourDeviceId,
            }).then(res=>{
                if(res.data.result.code === 200){
                    this.$message.success(res.data.result.msg)
                }
            }).catch(err=>{
                this.isError(err,this)
            })
        },
        //可复用数据设备
        getTableData(){
            this.loading = true
            getMusicElseDevice({
                jourDeviceId:this.row.jourDeviceId
                // jourDeviceId:'1578'
            }).then(res=>{
                this.loading = false
                this.tableData = res.data
            }).catch(err=>{
                this.loading = false
                this.isError(err,this)
            })
        },

    }
}
</script>

<style>

</style>