<template>
<div>
    <el-row>
        <el-button type="primary" @click="addMusic" icon="el-icon-folder-add">添加音乐</el-button>
        <el-button type="danger" @click="deletMusic" icon="el-icon-folder-delete">删除音乐</el-button>
        <el-button type="primary" @click="albumTube" icon="el-icon-s-management">专辑管理</el-button>
        <el-button type="primary" @click="emptyMusic">清空音乐</el-button>
        <el-button type="primary" @click="emptyAlbum">清空专辑</el-button>
        <el-button type="primary" @click="multiplexedData">复用数据</el-button>
        <!-- addMusic 添加音乐弹框 -->
        <el-dialog width="1200px" v-dialogDrag title="音乐管理" :modal-append-to-body="false" v-loading="loading" :visible.sync="addMusicDialog" append-to-body >
            <AddMusicDialog v-if="addMusicDialog" :row="row" :albumList="albumList" @initialPage="initialPageUpdata" ref="AddMusicDialog"/>
        </el-dialog>
        <!-- albumTube 专辑管理弹框 -->
        <el-dialog width="1200px" v-dialogDrag title="专辑管理" :modal-append-to-body="false" :visible.sync="albumDialog" append-to-body >
            <el-button type="primary" size="small" @click="addAlbumTube">添加专辑</el-button>
            <el-button type="primary" size="small" @click="deleAlbumTube">删除专辑</el-button>
            <el-table ref="multipleTable" :data="albumList" tooltip-effect="dark" height="500px" style="width: 100%" @selection-change="handleSelectionChange" @row-dblclick="dblClickAlbumTube" v-loading="deleAlbumTubeLoading">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="maSerialNum" label="专辑序号"></el-table-column>
                <el-table-column prop="maAlbumName" label="专辑名称"></el-table-column>
                <el-table-column prop="address" label="专辑音源" show-overflow-tooltip>
                  <!-- musicSource 混入里面的过滤器 -->
                  <template slot-scope="scope">{{ scope.row.maAlbumSource | musicSource }}</template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑" placement="left" effect="light">
                            <el-button type="primary" size="small" icon="el-icon-edit" circle></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除" placement="right" effect="light">
                            <el-button type="danger" size="small" icon="el-icon-delete" @click="deleMusic(scope.row)" circle></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!-- addAlbumTube 添加专辑弹框 -->
            <el-dialog width="1200px" v-dialogDrag title="添加专辑" :modal-append-to-body="false" :visible.sync="albumTubeDialog" append-to-body >
                <AlbumTubeDialog v-if="albumTubeDialog" :getAllAlbum="getAllAlbum" :row="row" ref="AlbumTubeDialog"/>
            </el-dialog>
        </el-dialog>
    </el-row>
    <!-- 表格 -->
    <MusicTable ref="MusicTable" @selctMusic="selctMusic"/>
    <!-- <el-row>
        <el-col :span="23" align="right">
            <el-button type="primary" >保存</el-button>
        </el-col>
    </el-row> -->
    </el-dialog>
    <!-- multiplexedData 复用数据弹窗 -->
    <el-dialog width="1200px" v-dialogDrag title="复用数据" :modal-append-to-body="false" :visible.sync="multiplexedDataDialog" append-to-body >
        <MultiplexedDataDialog v-if="multiplexedDataDialog" :row="row" ref="MultiplexedDataDialog"/>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="defineMultiplexed">确定复用</el-button>
        </div>
        </el-dialog>
</div>
</template>

<script>
import AddMusicDialog from './addMusicDialog'
import AlbumTubeDialog from './albumTubeDialog'
import MultiplexedDataDialog from './multiplexedDataDialog'
import MusicTable from './musicTable'
import { mixin } from './mixins/mixin'
import { resListDelete,musicAlbumDeleteMusic,musicAlbumFindMusic,musicAlbumDeleteMusicById,musicResListDeleteMusicResById } from '@/api/device'

export default {
    mixins: [mixin],
    props:{
        row:Object
    },
    data() {
        return {
            dialog:false,
            loading:false,
            addMusicDialog:false,
            deleAlbumTubeLoading:false,
            albumDialog:false,
            albumTubeDialog:false,
            multiplexedDataDialog:false,
            pageListLoading:false,
            albumList:[],
            addMusicTable:[],
            needToBeDeletAlbum:[],
            needToBeDeletMusic:[],
        }
    },
    components:{
        AddMusicDialog,
        AlbumTubeDialog,
        MultiplexedDataDialog,
        MusicTable,
    },
    created() {
        this.getAllAlbum()
        console.log(this.albumList)
    },
    computed:{
    },
    methods: {
        initialPageUpdata(){
            this.$refs.MusicTable.initialPage()
        },
        info(){
            this.dialog = true
        },
        selctMusic(data){
            console.log(data)
            this.needToBeDeletMusic = data;
        },
        handleSelectionChange(data){
            this.needToBeDeletAlbum = data
        },
        //查询所有专辑
        getAllAlbum(){
            this.deleAlbumTubeLoading = true
            return new Promise((resolve,reject)=>{
                musicAlbumFindMusic({
                    maAlbumName:'',//专辑名称
                    maAlbumSource:'',//专辑音源
                    maDevId:this.row.jourDeviceId,//设备ID
                    maSerialNum:'',//专辑序号
                }).then(res=>{
                    this.albumList = res.data
                    this.deleAlbumTubeLoading = false
                    return resolve()
                }).catch(err=>{
                    this.deleAlbumTubeLoading = false
                    this.isError(err,this)
                    return reject()
                })
            })
        },
        //清空专辑
        emptyAlbum(){
            let result = this.confirm('请确定清空所有专辑',this)
            result.then(res=>{
                console.log(this.row.jourDeviceId)
                // params:{id:}
                musicAlbumDeleteMusic({mrDevId:this.row.jourDeviceId}).then(res1=>{
                    if(res1.data.code == -1){
                        this.$message.warning(res1.data.msg)
                        return
                    }
                    this.$message.success(res1.data.msg)
                    this.getAllAlbum()
                }).catch(err=>{
                    this.isError(err,this)
                })
            })
        },
        //清空音乐
        emptyMusic(){
            let result = this.confirm('请确定清空所有音乐',this)
            result.then(res=>{
                resListDelete({devid:this.row.jourDeviceId}).then(res1=>{
                    if(res1.data.code == -1){
                        this.$message.warning(res1.data.msg)
                        return
                    }
                    this.$message.success(res1.data.msg)
                }).catch(err=>{
                    this.isError(err,this)
                })
            })
        },

        //添加音乐
        async addMusic(){
            await this.getAllAlbum()
            if(this.albumList.length < 1){
                this.$message.warning('没有查询到专辑，请先添加专辑')
                return
            }
            this.addMusicDialog = true;
        },
        deletMusic(){
            if(this.needToBeDeletMusic.length < 1){ this.$message.warning('请选择一条数据'); return }
           let arr = this.needToBeDeletMusic.map(item=>{return item.mrId})
            musicResListDeleteMusicResById(arr).then(({data})=>{
                this.$message.success(data.msg)
                this.$refs.MusicTable.initialPage()
            }).catch(err=>{
                this.isError(err,this)
            })
        },
        //专辑管理
        albumTube(){
            this.getAllAlbum()
            this.albumDialog = true
        },
        //添加专辑
        addAlbumTube(){
            this.albumTubeDialog = true
        },
        //删除专辑
        deleAlbumTube(){
            // await this.getAllAlbum()
            // if(this.albumList.length < 1){
            //     this.$message.warning('没有查询到专辑，请先添加专辑')
            //     return
            // }
            // this.deleAlbumDialog = true
            console.log(this.needToBeDeletAlbum)
            if(this.needToBeDeletAlbum.length < 1){ this.$message.warning('请选择需要删除的专辑');return}
            let maIdArr = this.needToBeDeletAlbum.map(item => {
                return item.maId;
            })
            musicAlbumDeleteMusicById(
                maIdArr,
            ).then(res=>{
                this.$message.success(res.data.msg)
                this.getAllAlbum()
            }).catch((err)=>{
                this.isError(err,this)
            })
        },
        multiplexedData(){//复用数据
            this.multiplexedDataDialog = true
        },
        defineMultiplexed(){
            this.$refs.MultiplexedDataDialog.defineMultiplexed()
        },
        dblClickAlbumTube(row){
            this.$refs.multipleTable.toggleRowSelection(row);
        }
    },
}
</script>

<style lang="scss" scope>
    .el-row{
        margin-bottom: 10px;
    }
</style>